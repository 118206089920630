<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="$emit('update:dialog', false);"
      max-width="60%"
    >
      <QuotationTable :listSkeleton.sync="listSkeleton" @selectCustomerQuotation="selectCustomerQuotation" :type.sync="type" :selectCustomerForDialog="selectedCustomer ? selectedCustomer : ''">
        <template v-slot:closeIcon>
          <v-card-text class="pa-0 text-end">
            <v-icon class="mt-2 primary--text" @click="$emit('update:dialog', false);">mdi-close</v-icon>
          </v-card-text>
        </template>
      </QuotationTable>
    </v-dialog>
  </div>
</template>
<script>
import QuotationTable from '@/views/companyApp/quotation/QuotationTable';
export default {
  name: "CustomerDialog",
  props: ["dialog", 'customer_id', 'selectedCustomer'],
  components: {
    QuotationTable
  },
  data() {
    return {
      listSkeleton: false,
      type: 'CustomerQuotation',
    }
  },
  watch: {
    dialog(val) {
      if(!val) {
        this.type = null;
      } else {
        this.type = 'CustomerQuotation';
        // this.$store.dispatch('quotation/GetQuotation', {page: 1, limit: 10, where_and: 'status|'+0, where: this.customer_id ? 'customer_id|'+this.customer_id : this.customer_id, order_by: 'quotation_date|desc'});
      }
    }
  },
  methods: {
    selectCustomerQuotation(item) {
      this.$emit('onQuotationChange', item);
      this.$emit('update:dialog', false);
    }
  },
}
</script>